<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="1000"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="3">
              <v-text-field
                label="First Name"
                v-model="selectedHouseholdMember.FirstName"
              ></v-text-field>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="3">
              <v-text-field
                label="Last Name"
                v-model="selectedHouseholdMember.LastName"
              ></v-text-field>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="3">
              <v-checkbox
                label="Is Property Tax Payer"
                v-model="selectedHouseholdMember.IsTaxPayer"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
            <v-col sm="12" md="3">
              <v-checkbox
                label="No Income Tax Filed"
                v-model="selectedHouseholdMember.NoIncomeTaxFiled"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="4" lg="3">
              <BaseCurrencyField
                label="Taxable Income"
                :allowNegative="true"
                v-model="selectedHouseholdMember.TaxableIncome"
              ></BaseCurrencyField>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="4" lg="3">
              <BaseCurrencyField
                label="Non Taxable Interest"
                :allowNegative="true"
                v-model="selectedHouseholdMember.NonTaxableInterest"
              ></BaseCurrencyField>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="4" lg="3">
              <BaseCurrencyField
                label="Non Taxable Distributions"
                :allowNegative="true"
                v-model="selectedHouseholdMember.NonTaxableDistributions"
              ></BaseCurrencyField>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="4" lg="3">
              <BaseCurrencyField
                label="Non Taxable SS"
                :allowNegative="true"
                v-model="selectedHouseholdMember.NonTaxableSs"
              ></BaseCurrencyField>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="12" md="4" lg="3">
              <BaseCurrencyField
                label="Other Income"
                :allowNegative="true"
                v-model="selectedHouseholdMember.OtherIncome"
              ></BaseCurrencyField>
            </v-col>
            <v-col sm="12" md="4" lg="3">
              <BaseCurrencyField
                label="Prior Year PTRS Refund"
                :disabled="isConsumer"
                v-model="selectedHouseholdMember.PriorYearPtrsRefund"
              ></BaseCurrencyField>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="12">
              <Application
                label="Application"
                v-model="selectedHouseholdMember.ApplicationId"
                  :rules="[v => !!v || 'required']"
              ></Application>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveHouseholdMemberDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import HouseholdMember from '@classes/HouseholdMember'
//templateTODO: import lookup components you plan to use

export default {
  components: {
    //templateTodo: define lookup components you are using
    //Application,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedHouseholdMember: new HouseholdMember(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...authComputed,
    ...get('householdMember', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('householdMember', ['saveHouseholdMember']),
    async validate() {
      await this.$refs.form.validate()
    },
    editHouseholdMember(entry) {
      this.selectedHouseholdMember = new HouseholdMember(entry)
      this.modalText = 'Edit Household Member'
      this.dialog = !this.dialog
    },
    addHouseholdMember(entry) {
      this.modalText = 'Insert Household Member'
      this.selectedHouseholdMember = new HouseholdMember(entry)
      this.dialog = !this.dialog
    },
    async saveHouseholdMemberDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveHouseholdMember(
            this.selectedHouseholdMember.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedHouseholdMember = new HouseholdMember()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedHouseholdMember = new HouseholdMember()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
