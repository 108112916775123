<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <ContactEntryForm
      ref="contactEntryForm"
      :dialog.sync="dialog"
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
    >
    </ContactEntryForm>
  </v-dialog>
</template>
<script>
import ContactEntryForm from '@components/form/staff/contactEntryForm.vue'

export default {
  components: {
    ContactEntryForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {},
  methods: {
    editContact(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.contactEntryForm.editContact(entry)
      })
    },
    addContact() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.contactEntryForm.addContact()
      })
    },
  },
}
</script>
<style></style>
