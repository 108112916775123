<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <!-- <v-row>
            <v-col sm="12">
              <Application
                label="Application"
                v-model="selectedApplicationStatusHistory.ApplicationId"
                  :rules="[v => !!v || 'required']"
              ></Application>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col sm="12">
              <LuApplicationStatus
                label="Status"
                v-model="selectedApplicationStatusHistory.StatusId"
                :rules="[(v) => !!v || 'required']"
                :items="availableStatuses"
              ></LuApplicationStatus>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="12" v-if="filteredReasons && filteredReasons.length > 0">
              <LuReason
                label="Reason"
                :items="filteredReasons"
                v-model="selectedApplicationStatusHistory.ReasonId"
                :rules="[(v) => !!v || 'required']"
                sortProperty="ReasonNumber"
              ></LuReason>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveApplicationStatusHistoryDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import ApplicationStatusHistory from '@classes/ApplicationStatusHistory'
//templateTODO: import lookup components you plan to use
import LuReason from '@components/select/LuReason/LuReason-a-single'
import LuApplicationStatus from '@components/select/LuApplicationStatus/LuApplicationStatus-a-single'
export default {
  props: {
    appId: {
      type: Number,
      required: true,
    },
    currentStatus: {
      type: Object,
    },
  },
  components: {
    //templateTodo: define lookup components you are using
    //Application,
    // LuReason,
    LuApplicationStatus,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedApplicationStatusHistory: new ApplicationStatusHistory(),
    modalText: '',
  }),
  created() {
    this.loadLuApplicationStatuses()
    // this.loadLuReasons()
  },
  computed: {
    ...authComputed,
    ...get('applicationStatusHistory', ['saving']),
    ...get('luApplicationStatus', ['luApplicationStatuses']),
    // ...get('luReason', ['luReasons']),
    availableStatuses() {
      let statuses = this.luApplicationStatuses.filter((x) => x.IsActive)
      if (this.currentStatus && this.currentStatus.StatusId > 0) {
        //we have a current status and need to find the statuses available as the next status
        let match = statuses.find(
          (x) => x.Id == this.currentStatus.StatusId
        )?.NextAvailableStatuses
        // filter the list down to ones available to one of the current user roles
        if (match) {
          let availableStatuses = match
            .filter((x) =>
              x.Roles.some(
                (a) =>
                  this.currentUser.Roles.findIndex((r) => r.Id == a.Id) > -1
              )
            )
            //and map the next status associated with the record
            .map((i) => {
              return i.NextStatus
            })
          return availableStatuses
        }
      } else {
        //a status has not been entered, so the only available one is the in progress (DAT) status
        return statuses.filter((x) => x.Id == 1)
      }
    },
    // filteredReasons() {
    //   if (
    //     this.selectedApplicationStatusHistory.StatusId > 0 &&
    //     this.availableStatuses &&
    //     this.availableStatuses.length > 0
    //   ) {
    //     return this.availableStatuses.find(
    //       (i) => i.Id == this.selectedApplicationStatusHistory.StatusId
    //     )?.LuReasons
    //   } else {
    //     var distinctReasons = []
    //     this.availableStatuses?.forEach((s) => {
    //       s?.LuReasons.forEach((r) => {
    //         if (distinctReasons.findIndex((y) => y.Id == r.Id) == -1) {
    //           distinctReasons.push(r)
    //         }
    //       })
    //     })
    //     return distinctReasons
    //   }
    // },
  },
  methods: {
    handleError: call('errors/handleError'),
    loadLuApplicationStatuses: call(
      'luApplicationStatus/loadLuApplicationStatuses'
    ),
    // loadLuReasons: call('luReason/loadLuReasons'),
    ...call('applicationStatusHistory', ['saveApplicationStatusHistory']),
    async validate() {
      await this.$refs.form.validate()
    },
    editApplicationStatusHistory(entry) {
      this.selectedApplicationStatusHistory = new ApplicationStatusHistory(
        entry
      )
      this.modalText = 'Edit Application Status History'
      this.dialog = !this.dialog
    },
    addApplicationStatusHistory() {
      this.modalText = 'Application Status'
      console.devlog({ appId: this.appId })
      this.dialog = !this.dialog
      this.selectedApplicationStatusHistory = new ApplicationStatusHistory({
        ApplicationId: this.appId,
      })
    },
    async saveApplicationStatusHistoryDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let status = this.selectedApplicationStatusHistory.removeRelated()
          // status.ApplicationId = this.appId
          let res = await this.saveApplicationStatusHistory(status)
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedApplicationStatusHistory = new ApplicationStatusHistory()
          this.$refs.form.resetValidation()
          this.dialog = false
        } catch (err) {
          console.error(err)
        }
        // this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedApplicationStatusHistory = new ApplicationStatusHistory()
      this.$refs.form.resetValidation()
    },
  },
  watch: {
    // 'selectedApplicationStatusHistory.ReasonId'(nval, oval) {
    //   if (nval && nval > 0 && this.luReasons && this.luReasons.length > 0) {
    //     let reason = this.luReasons.find((i) => i.Id == nval)
    //     if (reason && reason.StatusId) {
    //       this.selectedApplicationStatusHistory.StatusId = reason.StatusId
    //     }
    //   }
    // },
    // 'selectedApplicationStatusHistory.StatusId'(nval, oval) {
    //   if (
    //     nval &&
    //     nval > 0 &&
    //     this.selectedApplicationStatusHistory.ReasonId &&
    //     this.selectedApplicationStatusHistory.ReasonId > 0 &&
    //     this.availableStatuses &&
    //     this.availableStatuses.length > 0
    //   ) {
    //     let status = this.availableStatuses.find((i) => i.Id == nval)
    //     if (status && status.LuReasons && status.LuReasons.length > 0) {
    //       let match = status.LuReasons.find(
    //         (i) => i.Id == this.selectedApplicationStatusHistory.ReasonId
    //       )
    //       if (!match) {
    //         this.selectedApplicationStatusHistory.ReasonId = null
    //       }
    //     } else {
    //       this.selectedApplicationStatusHistory.ReasonId = null
    //     }
    //   }
    // },
  },
}
</script>
<style></style>
